import React, { useEffect, useRef, useState, useMemo } from "react";
import "./animation.scss";
import { images } from "../../constants";
import python from "./python.svg";
import tensorflow from "./tensorflow.svg";
import android from "./android.svg";
import javascript from "./node.png"

function Animation() {
  const orbitRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isVisible, setIsVisible] = useState(true);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });
  
  useEffect(() => {
    // Check if device is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Add mouse movement effect with smoother transitions
    const handleMouseMove = (event) => {
      if (!orbitRef.current) return;
      
      const { clientX, clientY } = event;
      const { innerWidth, innerHeight } = window;
      
      // Update mouse position with reduced sensitivity for slower movement
      setMousePosition({
        x: ((clientX / innerWidth) - 0.5) * 20, // Reduced from 30 to 20
        y: ((clientY / innerHeight) - 0.5) * -20 // Reduced from 30 to 20
      });
    };
    
    // Add device orientation effect for mobile
    const handleDeviceOrientation = (event) => {
      if (!orbitRef.current || !isMobile) return;
      
      const { beta, gamma } = event;
      
      if (beta === null || gamma === null) return;
      
      // Limit the rotation range with smoother values and reduced sensitivity
      const rotateX = Math.max(-15, Math.min(15, (beta - 40) * 0.3)) * -1; // Reduced sensitivity
      const rotateY = Math.max(-15, Math.min(15, gamma * 0.3)) * -1; // Reduced sensitivity
      
      // Update mouse position for consistent animation approach
      setMousePosition({ x: rotateY, y: rotateX });
    };
    
    // Check visibility on scroll
    const checkVisibility = () => {
      if (!orbitRef.current) return;
      
      const rect = orbitRef.current.getBoundingClientRect();
      const isInViewport = (
        rect.top >= -rect.height &&
        rect.left >= -rect.width &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + rect.height &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) + rect.width
      );
      
      setIsVisible(isInViewport);
    };
    
    // Apply the rotation with RAF for smoother animation and interpolation
    let rafId;
    const updateRotation = () => {
      if (orbitRef.current) {
        // Smooth interpolation between current position and target position
        setCurrentPosition(prevPos => ({
          x: prevPos.x + (mousePosition.x - prevPos.x) * 0.05, // Slower interpolation factor
          y: prevPos.y + (mousePosition.y - prevPos.y) * 0.05  // Slower interpolation factor
        }));
        
        orbitRef.current.style.transform = `rotateX(${currentPosition.y}deg) rotateY(${currentPosition.x}deg)`;
      }
      rafId = requestAnimationFrame(updateRotation);
    };
    
    // Start the animation loop
    rafId = requestAnimationFrame(updateRotation);
    
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);
    window.addEventListener('deviceorientation', handleDeviceOrientation);
    window.addEventListener('scroll', checkVisibility);
    
    // Initial check
    handleResize();
    checkVisibility();
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
      window.removeEventListener('scroll', checkVisibility);
      cancelAnimationFrame(rafId);
    };
  }, [isMobile, mousePosition, currentPosition]);

  // Generate completely static stars using useMemo to ensure they don't change on re-renders
  const stars = useMemo(() => {
    const starElements = [];
    const starCount = isMobile ? 15 : 25;
    
    for (let i = 0; i < starCount; i++) {
      // Create static star distribution
      const size = Math.random() * 4 + 1;
      const opacity = Math.random() * 0.5 + 0.2;
      const hue = Math.random() * 40 + 200; // Blue-ish hues
      
      starElements.push(
        <div 
          key={i} 
          className="star"
          style={{
            width: `${size}px`,
            height: `${size}px`,
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            opacity: opacity,
            backgroundColor: `hsl(${hue}, 80%, 70%)`,
            boxShadow: `0 0 ${size * 2}px hsl(${hue}, 80%, 70%)`
          }}
        ></div>
      );
    }
    
    return starElements;
  }, [isMobile]); // Only regenerate if mobile status changes

  return (
    <div className={`showcase-container ${isVisible ? 'visible' : 'hidden'}`}>
      {/* Static stars background */}
      <div className="stars-container">
        {stars}
      </div>
      
      <div className="orbit-system" ref={orbitRef}>
        {/* Central logo with parallax effect */}
        <div className="center-logo" style={{ transform: `translate(-50%, -50%) translateZ(${isMobile ? 30 : 50}px)` }}>
          <div className="logo-container">
            <img 
              src={images.hyphenimage} 
              alt="Hyphen Tech" 
              className="center-image"
            />
          </div>
        </div>
        
        {/* Orbiting items with parallax depths */}
        <div className="orbit orbit-1" style={{ transform: `translate(-50%, -50%) translateZ(${isMobile ? 15 : 30}px)` }}>
          <div className="orbit-path"></div>
          <div className="satellite satellite-1">
            <div className="satellite-content">
              <img src={javascript} alt="NodeJs" />
            </div>
          </div>
          <div className="satellite satellite-2">
            <div className="satellite-content">
              <img src={python} alt="Python" />
            </div>
          </div>
        </div>
        
        <div className="orbit orbit-2" style={{ transform: `translate(-50%, -50%) translateZ(${isMobile ? 0 : 0}px)` }}>
          <div className="orbit-path"></div>
          <div className="satellite satellite-3">
            <div className="satellite-content">
              <img src={tensorflow} alt="TensorFlow" />
            </div>
          </div>
          <div className="satellite satellite-4">
            <div className="satellite-content">
              <img src={images.agrihyphen} alt="AgriHyphen" />
            </div>
          </div>
        </div>
        
        <div className="orbit orbit-3" style={{ transform: `translate(-50%, -50%) translateZ(${isMobile ? -15 : -30}px)` }}>
          <div className="orbit-path"></div>
          <div className="satellite satellite-5">
            <div className="satellite-content">
              <img src={images.consulting} alt="Consulting" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Animation;
