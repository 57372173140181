import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ProjectBanner.scss';

const ProjectBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation();

  // Add effect to adjust body padding when banner visibility changes
  useEffect(() => {
    // Get banner height
    const banner = document.querySelector('.project-banner');
    if (!banner) return;
    
    const bannerHeight = isVisible ? banner.offsetHeight : 0;
    
    // Adjust navbar position
    document.documentElement.style.setProperty(
      '--banner-height', 
      `${bannerHeight}px`
    );
    
    // Clean up
    return () => {
      document.documentElement.style.setProperty('--banner-height', '0px');
    };
  }, [isVisible]);

  // Handle closing the banner
  const closeBanner = () => {
    setIsVisible(false);
    // Update the CSS variable immediately to prevent layout jumps
    document.documentElement.style.setProperty('--banner-height', '0px');
  };

  return (
    <div 
      className="project-banner" 
      style={{ 
        transform: isVisible ? 'translateY(0)' : 'translateY(-100%)',
        opacity: isVisible ? 1 : 0,
      }}
    >
      <div className="banner-content">
        <span className="desktop-only">{t('Visit our ongoing project')}</span>
        <span className="mobile-only">Visit</span>
        
        <a 
          href="https://agri.hyphentech.tech/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <span className="desktop-only">{t('Check out AgriHyphen AI')}</span>
          <span className="mobile-only">AgriHyphen AI</span>
          <svg 
            width="12" 
            height="12" 
            viewBox="0 0 12 12" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="arrow-icon"
          >
            <path 
              d="M3.5 3C3.5 2.72386 3.72386 2.5 4 2.5H8.5C8.77614 2.5 9 2.72386 9 3V7.5C9 7.77614 8.77614 8 8.5 8C8.22386 8 8 7.77614 8 7.5V4.20711L3.35355 8.85355C3.15829 9.04882 2.84171 9.04882 2.64645 8.85355C2.45118 8.65829 2.45118 8.34171 2.64645 8.14645L7.29289 3.5H4C3.72386 3.5 3.5 3.27614 3.5 3Z" 
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
      <button 
        className="close-button" 
        onClick={closeBanner}
        aria-label="Close banner"
      >
        ×
      </button>
    </div>
  );
};

export default ProjectBanner; 