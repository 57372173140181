import email from '../assets/email.svg';
import whatsapp from "../assets/whatsapp.svg"
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';
import java from '../assets/java.png'
import mongodb from '../assets/mongodb.png'
import agrihyphen from "../assets/agrihyphen.svg"
import consulting from "../assets/Consulting.svg"

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import hyphenlogo from '../assets/hyphenlogo.png'
import hyphensvg from '../assets/hyphensvg.svg'

import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';

import diego from '../assets/photos/Diego.png'
import speech from '../assets/photos/speech.jpg'
import educ from '../assets/photos/education.jpg'
import hyphenimage from "../assets/4.png"
import tensorflow from "../assets/tensorflow.svg"

// Default fallback image
import fallbackImage from '../assets/hyphenlogo.png';
import yan1 from "../assets/yan.jpeg"
import ifb1 from "../assets/ifb-audience.png"
import diego1 from "../assets/Diego.png"
import speech1 from "../assets/speech.jpg"
import education1 from "../assets/education.jpg"
import innovation1 from "../assets/innovation-week.jpg"

// Map image paths to their fallback
const blogImages = {
  './photos/yan.jpeg': yan1,
  './photos/ifb-audience.png': ifb1,
  './photos/Diego.png': diego1,
  './photos/speech.jpg': speech1,
  './photos/education.jpg': education1,
  './photos/innovation-week.jpg':innovation1,
};

export const getBlogImage = (imagePath) => {
  return blogImages[imagePath] || fallbackImage;
};

export default {
  email,
  hyphenimage,
  tensorflow,
  whatsapp,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
  java,
  mongodb,
  hyphenlogo,
  hyphensvg,
  fallbackImage,
  diego,
  speech,
  educ,
  agrihyphen,
  consulting,
  getBlogImage
};
