import React from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import fb from "./facebook-color-svgrepo-com.svg";
import linkedin from "./linkedin.svg"; // Make sure to add LinkedIn icon

const Footer = () => {
  const { t } = useTranslation();

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={containerVariants}
      className="footer-container"
    >
      <motion.h2 
        className="head-text"
        variants={itemVariants}
      >
        {t("Connect with Us")}
      </motion.h2>

      <div className="footer-content">
        <motion.div 
          className="contact-info"
          variants={itemVariants}
        >
          <h3>{t("Contact Information")}</h3>
          <div className="app__footer-cards">
            <motion.div 
              className="app__footer-card"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <img src={images.email} alt="email" />
              <a href="mailto:info@hyphentech.tech">info@hyphentech.tech</a>
            </motion.div>

            <motion.div 
              className="app__footer-card"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <img src={images.email} alt="email" />
              <a href="mailto:hyphentechinfo@gmail.com">hyphentechinfo@gmail.com</a>
            </motion.div>
          </div>
        </motion.div>

        <motion.div 
          className="social-connect"
          variants={itemVariants}
        >
          <h3>{t("Connect With Us")}</h3>
          <div className="social-links">
            <motion.a
              href="https://wa.me/25762153272"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link whatsapp"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <img src={images.whatsapp} alt="WhatsApp" />
              <span>+257 62 15 32 72</span>
            </motion.a>

            <motion.a
              href="https://wa.me/25762674277"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link whatsapp"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <img src={images.whatsapp} alt="WhatsApp" />
              <span>+257 62 67 42 77</span>
            </motion.a>

            <motion.a
              href="https://www.facebook.com/profile.php?id=100064389290696"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link facebook"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <img src={fb} alt="Facebook" />
              <span>Facebook</span>
            </motion.a>

            <motion.a
              href="https://www.linkedin.com/company/hyphen-tech-1/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link linkedin"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <img src={linkedin} alt="LinkedIn" />
              <span>LinkedIn</span>
            </motion.a>
          </div>
        </motion.div>
      </div>

      <motion.div 
        className="copyright"
        variants={itemVariants}
      >
        <p>
          &copy; {new Date().getFullYear()} Hyphen Tech. {t("All rights reserved")}
        </p>
      </motion.div>
    </motion.div>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
