import React, { useEffect } from "react";
import "./Portfolio.scss";
import "./Blog.scss";
import Card from "./Card";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { useNavigate, useLocation } from "react-router-dom";
import data from "../../data/index.json";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // Get blog posts from data
  const blogPosts = data.BlogApi;

  useEffect(() => {
    // Restore scroll position if coming back from blog details
    if (location.state?.scrollPosition) {
      window.scrollTo(0, location.state.scrollPosition);
    }
  }, [location.state]);

  const handleCardClick = (id) => {
    // Save current scroll position before navigating
    navigate(`/blog/${id}`, { 
      state: { 
        scrollPosition: window.scrollY 
      } 
    });
  };

  return (
    <>
      <h2 className="head-text">{t("The hyphen Blog")}</h2>

      <div className="app__profiles">
        {blogPosts.map((blog) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3, type: "tween" }}
            className="app__profile-item"
            key={blog.id}
          >
            <Card 
              blog={blog} 
              onClick={() => handleCardClick(blog.id)} 
            />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Blog, "app__about"), "blog", "app__whitebg");
