import React, { useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import data from "../../data/index.json";
import "./BlogDetails.scss";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { getBlogImage } from "../../constants/images";

const BlogDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const blog = data.BlogApi.find((blog) => blog.id === parseInt(id));

  const handleBackClick = () => {
    // Navigate back to blog section and scroll to previous position
    navigate('/#blog', { 
      state: { 
        scrollPosition: location.state?.scrollPosition || 0 
      } 
    });
  };

  if (!blog) {
    return (
      <div className="blog-details-container">
        <div className="blog-details-wrapper">
          <button className="back-button" onClick={handleBackClick}>
            <span>&larr;</span> {t("Articles")}
          </button>
          <div className="blog-not-found">
            <h2>{t("Blog post not found")}</h2>
            <p>{t("The requested blog post could not be found.")}</p>
          </div>
        </div>
      </div>
    );
  }

  const blogImage = getBlogImage(blog.image);

  return (
    <div className="blog-details-container">
      <div className="blog-details-wrapper">
        <div className="blog-header-nav">
          <button className="back-button" onClick={handleBackClick}>
            <span>&larr;</span> {t("Articles")}
          </button>
          <div className="blog-date">{blog.date}</div>
        </div>
        
        <motion.div 
          className="blog-content"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="blog-header">
            <h1 className="blog-title">{t(blog.title_one)}</h1>
          </div>

          <div className="blog-hero-image">
            <img src={blogImage} alt={t(blog.title_one)} loading="eager" />
          </div>

          <div className="blog-body">
            <div className="blog-section">
              <p className="blog-text">{t(blog.desc_one)}</p>
            </div>

            {blog.title_two && (
              <div className="blog-section">
                <h2 className="section-title">{t(blog.title_two)}</h2>
                <p className="blog-text">{t(blog.desc_two)}</p>
              </div>
            )}

            {blog.title_three && (
              <div className="blog-section">
                <h2 className="section-title">{t(blog.title_three)}</h2>
                <p className="blog-text">{t(blog.desc_three)}</p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BlogDetails;
