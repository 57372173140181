import { useEffect } from "react";
import Animation from "./Animation";
import { Link } from "react-scroll";
import { AppWrap } from "../../wrapper";
import "./Header.scss";
import { useTranslation } from "react-i18next";

function HeroSection() {
  const { t } = useTranslation();

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content-wrapper">
        <div className="hero--section--content">
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Hyphen Tech</span>{" "}
          </h1>
          <p className="section--title">
            {t("Empowering Success Through Technology")}
          </p>
          <br />
          <p className="hero--section-description">
            {t("Transforming lives and scaling businesses with technology")}
          </p>
        </div>
        <div className="button-container">
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="btn btn-github"
          >
            {t("Get In Touch")}
          </Link>

          <style>
            {`
          .btn-green {
            background-color: white; /* Background color */
            color: green; /* Text color */
            border: 2px solid green; /* Optional: Add a border */
            padding: 10px 20px; /* Adjust padding */
            border-radius: 5px; /* Round corners */
            cursor: pointer; /* Change cursor on hover */
            transition: background-color 0.3s, color 0.3s; /* Smooth transition */
          }

          .btn-green:hover {
            background-color: green; /* Change background color on hover */
            color: white; /* Change text color on hover */
          }

          @media (max-width: 768px) {
            .button-container {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .btn {
              width: 100%; /* Full width on smaller screens */
              margin-bottom: 10px; /* Spacing between buttons */
            }
          }
          
          /* Fix for hero section spacing */
          @media (max-width: 768px) {
            #heroSection {
              grid-template-columns: 1fr;
              padding: 80px 40px;
              gap: 20px;
            }
            .hero--section--img {
              margin-top: 0;
            }
          }
          
          @media (max-width: 480px) {
            #heroSection {
              padding: 60px 20px;
            }
          }
        `}
          </style>
          {/* <Link
            to="https://agri.hyphentech.tech/"
            target="_blank"
            className="btn btn-green"
          >
            Visit AgriHyphen AI our ongoing project
          </Link> */}
        </div>
      </div>
      <div className="hero--section--img">
        <Animation />
      </div>
    </section>
  );
}

export default AppWrap(HeroSection, "home");
