import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./showcase.scss";
import data from "../../data/index.json"; // Adjust the path as needed
import { useTranslation } from "react-i18next";

function Experience() {
  const [selected, setSelected] = useState(0);
  const showcase = data.showcase;
  const { t } = useTranslation();

  useEffect(() => {
    const underline = document.querySelector(".underline");
    const selectedItem = document.querySelector(".exp-slider-item-selected");

    if (underline && selectedItem) {
      const itemHeight = selectedItem.offsetHeight;
      const itemTop = selectedItem.offsetTop;
      
      // Smooth transition for underline
      underline.style.transition = "all 0.3s ease-in-out";
      underline.style.height = `${itemHeight}px`;
      underline.style.top = `${itemTop}px`;
    }
  }, [selected]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <motion.div
      className="experience"
      id="experience"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
      variants={containerVariants}
    >
      <motion.div 
        className="title"
        variants={itemVariants}
      >
        <h2>{t("The Showcase Study")}</h2>
        <div className="title-underline"></div>
      </motion.div>

      <div className="container">
        <motion.ul 
          className="exp-slider" 
          role="tablist" 
          aria-label="Experience tabs"
          variants={itemVariants}
        >
          <div className="underline"></div>
          {showcase.map((experience, index) => (
            <motion.li
              className={`exp-slider-item ${
                index === selected ? "exp-slider-item-selected" : ""
              }`}
              onClick={() => setSelected(index)}
              key={experience.name}
              role="tab"
              aria-selected={index === selected ? "true" : "false"}
              tabIndex={index === selected ? "0" : "-1"}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <span>{experience.name}</span>
            </motion.li>
          ))}
        </motion.ul>

        <motion.div 
          className="exp-details"
          variants={itemVariants}
        >
          <div className="exp-details-position">
            <h3>
              <span>{t(showcase[selected].role)}</span>
              <span className="exp-details-position-company">
                &nbsp;@&nbsp;
                <a
                  href={showcase[selected].url}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {showcase[selected].name}
                </a>
              </span>
            </h3>
            <p className="exp-details-range">
              {showcase[selected].start} - {showcase[selected].end}
            </p>
            <ul className="exp-details-list">
              {showcase[selected].shortDescription.map((description, index) => (
                <motion.li 
                  key={index} 
                  className="exp-details-list-item"
                  variants={itemVariants}
                >
                  {t(description)}
                </motion.li>
              ))}
            </ul>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Experience;
